import * as React from "react";
import { Header, Segment, Icon, SemanticICONS } from "semantic-ui-react";

interface PageTitleProps {
  iconName: SemanticICONS;
  title: string;
  subtitle?: string;
}

const PageTitle = ({ iconName, title, subtitle }: PageTitleProps) => {
  return (
    <Segment vertical>
      <Header as="h2">
        <Icon name={iconName} />
        <Header.Content>
          {title}
          <Header.Subheader>{subtitle}</Header.Subheader>
        </Header.Content>
      </Header>
    </Segment>
  );
};

export default PageTitle;
